import { WagtailPage, WagtailStreamfieldImage, WagtailSvg } from '@/types';
import { addBaseUrl } from '@/utils/url';
import Image from 'next/image';
import Link from 'next/link';

type LinkTile = {
  title: string;
  button_page: WagtailPage | null;
  button_url: string | null;
  image: WagtailStreamfieldImage | null;
  svg: WagtailSvg | null;
};

type Props = {
  link_kacheln: LinkTile[];
};

function Links(props: Props) {
  const { link_kacheln: linkKacheln } = props;

  return (
    <div className='streamfield links'>
      {linkKacheln.map(tile => (
        <Link
          key={tile.title}
          // href={tile.button_page ? removeBaseUrl(tile.button_page.full_url) : tile.button_url ?? ''}
          href='/'
          className='links__tile'
          legacyBehavior
        >
          <div className='title'>{tile.title}</div>
          {tile.image ? (
            <Image
              src={addBaseUrl(tile.image.file.src)}
              alt={tile.image.file.alt}
              className='icon'
              width={78}
              height={78}
            />
          ) : null}
          {tile.svg ? (
            <Image src={tile.svg.file} alt={tile.svg.title} width={78} height={78} layout='fixed' />
          ) : null}
        </Link>
      ))}
    </div>
  );
}

export default Links;
