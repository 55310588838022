import { WagtailArticlePageSummary, WagtailStreamfieldImage } from '@/types';
import RawHtml from '@components/RawHtml';
import { addBaseUrl, removeBaseUrl } from '@utils/url';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

type Props = {
  article: WagtailArticlePageSummary;
  arrangement: 'image_left' | 'image_right';
  unrelatedImage: WagtailStreamfieldImage | null;
  description: string;
};

function ArticleTeaser(props: Props) {
  const { article, arrangement, unrelatedImage, description } = props;

  return (
    <Link
      href={removeBaseUrl(article.full_url)}
      className={clsx('streamfield', 'article-teaser', {
        'article-teaser--reversed': arrangement === 'image_right',
      })}
    >
      <Image
        className='article-teaser__image'
        alt=''
        src={addBaseUrl(unrelatedImage?.file.src ?? '')}
        width={unrelatedImage?.file.width}
        height={unrelatedImage?.file.height}
      />
      <div className='article-teaser__content'>
        <h2 className='article-teaser__title'>{article.title}</h2>
        <RawHtml html={description} />
        <p className='article-teaser__read-more'>Find out more</p>
      </div>
    </Link>
  );
}

export default ArticleTeaser;
