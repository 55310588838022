import ArticleTeaser from './ArticleTeaser';
import BigQuote from './BigQuote';
import ButtonBlock from './ButtonBlock';
import CTA from './CTA';
import CodeBlock from './CodeBlock';
import Container from './Container';
import ContentMarketing from './ContentMarketing';
import Download from './Download';
import DownloadGroup from './DownloadGroup';
import HighlightQuote from './HighlightQuote';
import Image from './Image';
import LatestArticles from './LatestArticles';
import Links from './Links';
import Message from './Message';
import Program from './Program';
import Richtext from './Richtext';
import Sources from './Sources';
import Table from './Table';
import Video from './Video';

const streamfields = {
  cta: CTA,
  links: Links,
  message: Message,
  richtext: Richtext,
  simple_richtext: Richtext,
  full_richtext: Richtext,
  table: Table,
  code: CodeBlock,
  article_teaser: ArticleTeaser,
  quote: BigQuote,
  download: Download,
  highlight: HighlightQuote,
  downloads: DownloadGroup,
  image: Image,
  sources: Sources,
  button: ButtonBlock,
  content_marketing: ContentMarketing,
  program: Program,
  container: Container,
  latest_articles: LatestArticles,
  video: Video,
};

export type Streamfields = keyof typeof streamfields;

export default streamfields;
