import { Citation } from '@/types';
import Source from '@streamfields/Source/Source';

type Props = {
  sources: Citation[];
};

function Sources(props: Props) {
  const { sources } = props;
  return (
    <div className='streamfield sources__container'>
      <h4>Quellen:</h4>

      {sources?.map(source => (
        <Source
          key={sources.indexOf(source)}
          author={source.author}
          title={source.title}
          source_url={source.source_url}
        />
      ))}
    </div>
  );
}

export default Sources;
