import { WagtailPage, WagtailStreamfieldImage } from '@/types';
import React from 'react';

import Image from 'next/image';
import { addBaseUrl, removeBaseUrl } from '@utils/url';
import Link from 'next/link';

type Article = {
  title: string;
  subtitle: string | null;
  primary_image?: WagtailStreamfieldImage;
  url_path: string;
};

type Props = { title: string | null; articles: Article[]; page: WagtailPage };

function LatestArticles(props: Props) {
  const { title, articles, page } = props;

  return (
    <div className='streamfield latest-articles'>
      <h2>{title}</h2>
      <ul className='latest-articles__list'>
        {articles.map(article => (
          <li key={article.title} className='latest-articles__list-item'>
            <Link href={article.url_path}>
              {article.primary_image ? (
                <Image
                  src={addBaseUrl(article.primary_image?.file.src)}
                  alt={article.primary_image?.file.alt}
                  width={article.primary_image?.file.width}
                  height={article.primary_image?.file.height}
                  className='latest-articles__image'
                />
              ) : null}
              <div className='latest-articles__list-item-content'>
                <h3>{article.title}</h3>
                {article.subtitle ? <p>{article.subtitle}</p> : null}
              </div>
            </Link>
          </li>
        ))}
      </ul>
      <Link href={removeBaseUrl(page.full_url)} className='latest-articles__link'>
        Show all posts
      </Link>
    </div>
  );
}

export default LatestArticles;
