import Streamfields from '@streamfields/Streamfields';
import React from 'react';

type Props = {};

function Container(props: Props) {
  return (
    <div className='streamfield container-block'>
      <Streamfields fields={Object.values(props)} />
    </div>
  );
}

export default Container;
