import RawHtml from '@components/RawHtml';

const formatter = Intl.DateTimeFormat('en-DE', { dateStyle: 'long' });

type ProgramPoint = {
  date: string;
  title: string;
  description: string;
};

type Props = { [key: string]: ProgramPoint };

function Program(props: Props) {
  return (
    <div className='streamfield program'>
      {Object.values(props).map((item, index) => (
        <div key={item.title} className='program__point'>
          <div className='program__point-header'>
            <span>{`000${index + 1}`.slice(-2)}</span>
            <span aria-hidden />
          </div>
          <h2 className='program__point-title'>
            <strong>{formatter.format(new Date(item.date))}</strong>
            {item.title}
          </h2>
          <RawHtml html={item.description} />
        </div>
      ))}
    </div>
  );
}

export default Program;
