import RawHtml from '@components/RawHtml';

type Props = {
  highlight_text: string;
  author: string;
};

function HighlightQuote(props: Props) {
  const { highlight_text, author } = props;

  const startTag = highlight_text.slice(0, highlight_text.indexOf('>') + 1);
  const endTag = highlight_text.slice(highlight_text.lastIndexOf('<'), highlight_text.length);
  const mainText = highlight_text.slice(
    highlight_text.indexOf('>') + 1,
    highlight_text.lastIndexOf('<'),
  );

  const QuoteHtml = `${startTag}„${mainText}“${endTag}`;

  return (
    <blockquote className='streamfield highlight-quote'>
      <RawHtml html={QuoteHtml} className='highlight__text' />
      {author ? <footer className='highlight-quote__author'>– {author}</footer> : null}
    </blockquote>
  );
}

export default HighlightQuote;
