import { WagtailStreamfieldImage } from '@/types';
import { addBaseUrl } from '@utils/url';
import NextImage from 'next/image';

function Image(props: WagtailStreamfieldImage) {
  const {
    file: { src, width, height, alt },
  } = props;

  return (
    <div className='streamfield image'>
      <NextImage
        className='image__element'
        src={addBaseUrl(src)}
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
}

export default Image;
