/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

type Props = {
  file: string;
  thumbnail: string | null;
};

function Video(props: Props) {
  const { file, thumbnail } = props;

  return (
    <div className='video'>
      <video
        src={file}
        className='video__player'
        poster={thumbnail ?? undefined}
        preload='metadata'
        controls
      />
    </div>
  );
}

export default Video;
