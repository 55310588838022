import RawHtml from '@components/RawHtml';
import Link from 'next/link';
import KeilSvg from '@images/keil.svg';
import { removeBaseUrl } from '@utils/url';

type Props = {
  title: string;
  copy: string;
  form_handle_page: {
    full_url: string;
  };
};

function ContentMarketing(props: Props) {
  const { title, copy, form_handle_page: formHandlePage } = props;

  return (
    <div className='streamfield content-marketing'>
      <div className='streamfield content-marketing__container'>
        <KeilSvg className='content-marketing__logo' width={300} height={300} />
        <div className='content-marketing__content'>
          <h2 className='content-marketing__title'>{title}</h2>
          <RawHtml html={copy} className='content-marketing__text' />
          <Link className='content-marketing__link' href={removeBaseUrl(formHandlePage.full_url)}>
            Zur Anmeldung
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ContentMarketing;
